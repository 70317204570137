import loadable from '@loadable/component'

const routes = [
  {
    path: '/pc',
    component: loadable(() => import(/* webpackChunkName: "pc" */ './views/PC')),
  },
  {
    path: '/mobile',
    component: loadable(() => import(/* webpackChunkName: "mobile" */ './views/Mobile')),
  },
]

export const baseName = '/'

export default routes
