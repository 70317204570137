import React from "react"
import ReactDOM from 'react-dom'
import {
  BrowserRouter,
  HashRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"

import routes, { baseName } from './router'

import './base.less'

function App() {
  const isMobile = !!navigator.userAgent.match(/AppleWebKit.*Mobile.*/)

  return (
    <div className="root">
      {/* <BrowserRouter basename={baseName}> */}
      <HashRouter basename={baseName}>
        <Switch>
          <Route exact path='/'>
            <Redirect to={isMobile ? '/mobile' : '/pc'} />
          </Route>
            {
              routes.map((route:any, index) => (
                <Route path={ route.path } key={ index } exact render={ props => (
                  <route.component {...props} routes={route.routes} />
                )} />
              ))
            }
          </Switch>
      {/* </BrowserRouter> */}
      </HashRouter>
    </div>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById('app') as HTMLElement,
)
